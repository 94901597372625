<template>
  <Tour v-if="show === true" :rows="rows" @onEnd="done(key)"></Tour>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue';
import Tour from '@/modules/tour/Index.vue';

const key = 'assistant';
const { done, status } = inject('tour');
//
const rows = [
  {
    target: '[data-v-step="1"]',
    header: {
      title: '我的錯題',
    },
    content: `交卷後會將作答錯誤/未作答試題整理於此區(不含不計分試題)。下次測驗答對者會將該題從此區移除。`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '[data-v-step="2"]',
    header: {
      title: '收藏題庫',
    },
    content: `將特別關注的試題收藏於此。可利用篩選清單讓範圍更精準。`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '[data-v-step="3"]',
    header: {
      title: '收藏試卷',
    },
    content: `將特別關注的試卷收藏於此。可利用篩選清單讓範圍更精準。`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '[data-v-step="4"]',
    header: {
      title: '我的註解',
    },
    content: `在試題解析區下過註解的試題會被顯示在此區。`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '[data-v-step="5"]',
    header: {
      title: '測驗紀錄',
    },
    content: `將有點選交卷的測驗紀錄整理在此區。點選特定測驗進入查看上次作答結果。`,
    params: {
      enableScrolling: false,
    },
  },
];

const show = ref(null);
onMounted(() => {
  show.value = status[key];
});
</script>

<style lang="sass" scoped>
:deep(.v-step)
  &::after
    background-image: url(~@/assets/imgs/menuRole3.png)
</style>
