<template>
  <div
    class="flex-grow"
    :class="
      $route.query.type === 'topic' ? 'bg-background-2' : 'bg-background-1'
    "
  >
    <div
      class="flex bg-gray-100 items-center justify-center py-4 space-x-12 text-gray submenus md:space-x-0 md:justify-around"
    >
      <a href="" class="subMenu"></a>
      <router-link
        data-v-step="1"
        :to="{
          name: 'Assistant.Wrong',
          query: { type: 'wrong' },
        }"
        class="subMenu"
      >
        <font-awesome-icon :icon="['far', 'times-circle']" />
        我的錯題
      </router-link>
      <router-link
        data-v-step="2"
        :to="{
          name: 'Assistant.Collect',
          query: { type: 'question' },
        }"
        class="subMenu"
      >
        <font-awesome-icon :icon="['fas', 'book']" />
        收藏題庫</router-link
      >
      <router-link
        data-v-step="3"
        :to="{
          name: 'Assistant.Like',
          query: { type: 'past' },
        }"
        class="subMenu"
      >
        <font-awesome-icon :icon="['fas', 'scroll']" />
        收藏試卷</router-link
      >
      <router-link
        data-v-step="4"
        :to="{
          name: 'Assistant.Memo',
          query: { type: 'memo' },
        }"
        class="subMenu"
      >
        <font-awesome-icon :icon="['fas', 'pen']" />
        我的註解</router-link
      >
      <router-link
        data-v-step="5"
        :to="{
          name: 'Assistant.Tester',
          query: { type: 'tester' },
        }"
        class="subMenu"
      >
        <font-awesome-icon :icon="['fas', 'clock']" />
        測驗紀錄</router-link
      >
    </div>
    <div class="container pt-12">
      <router-view :key="$route.path"></router-view>
    </div>

    <Tour />
  </div>
</template>
<script>
import useRouteReload from '@/hook/useRouteReload';
import Tour from './_Tour.vue';

export default {
  components: {
    Tour,
  },
  setup() {
    const { isAlive } = useRouteReload();

    return { isAlive };
  },
};
</script>
<style lang="scss" scoped>
.router-link-active {
  @apply pointer-events-none;
}
</style>
<style lang="sass" scoped>
.submenus
  .subMenu
    @apply flex items-center hover:text-primary duration-300
    @screen md
      @apply text-sm
    svg
      @apply w-4 h-4 mr-2 #{!important}
  :deep(.router-link-active)
    @apply text-primary
</style>
