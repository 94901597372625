import { useRoute, useRouter } from 'vue-router';
import { ref, watch, nextTick } from 'vue';
import _ from 'lodash';
export default () => {
  const route = useRoute();
  const isAlive = ref(true);
  const reload = () => {
    isAlive.value = false;
    nextTick(() => {
      isAlive.value = true;
    });
  };
  watch(
    () => _.cloneDeep(route.params),
    (val, old) => {
      if (!_.isEqual(val, old)) {
        reload();
      }
    },
  );
  return {
    isAlive,
    reload,
  };
};
